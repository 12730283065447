import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { SCREEN_SIZE } from '../../../style';
import { SideNavigationItem } from './SideNavigationItem';

const StyledSideNavigation = styled.div`
  padding-right: 2rem;
  display: flex;
  flex-flow: wrap;
  ${SCREEN_SIZE.LG} {
    width: 10rem;
    display: block;
  }
`;

const SideNavigation = ({ activeLink, links }) => (
  <StyledSideNavigation>
    {links.map(({ text, url }) => (
      <SideNavigationItem
        key={url}
        url={url}
        active={activeLink === url}
      >
        {text}
      </SideNavigationItem>
    ))}
  </StyledSideNavigation>
);

const LinkProp = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

SideNavigation.propTypes = {
  activeLink: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape(LinkProp)).isRequired,
};

export { SideNavigation };