import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { HeaderText } from '../HeaderText';

const StyledSubSectionHeader = styled(HeaderText).attrs({
  priority: 3,
})`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const SubSectionHeader = ({ children }) => (
  <StyledSubSectionHeader>{children}</StyledSubSectionHeader>
);

SubSectionHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export { SubSectionHeader };