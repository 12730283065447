import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Layout } from '../../components/shared/layout';
import { Page } from '../../components/shared/Page';
import { LegalBanner } from '../../components/modules/LegalPage';
import { LegalContent } from '../../components/modules/LegalPage/LegalContent';
import { LEGAL_TYPES } from '../../components/modules/LegalPage/LegalContent/LegalTypes';

const StyledLegalPage = styled(Layout)`
  text-align: center;
`;

const LegalPage = ({ type }) => (
  <StyledLegalPage headerTransparent>
    <Page>
      <LegalBanner />
      <LegalContent type={type} />
    </Page>
  </StyledLegalPage>
);

LegalPage.defaultProps = {
  type: LEGAL_TYPES.TERMS_OF_SERVICE,
};

LegalPage.propTypes = {
  type: PropTypes.string,
};

export default LegalPage;
