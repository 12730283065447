import styled from 'styled-components';
import { SCREEN_SIZE } from '../../../style';

const BannerContentItem = styled.div`
  flex: ${({ flexSize }) => flexSize || 1};
  text-align: center;

  ${SCREEN_SIZE.LG} {
    padding: 0 .5rem;
  }
`;

export { BannerContentItem };