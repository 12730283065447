import styled from 'styled-components';
import { SCREEN_SIZE, STYLES } from '../../../style';

const BannerDescription = styled.div`
  font-size: 1rem;
  line-height: 1.875rem;
  color: ${STYLES.COLOR_WHITE};
  
  ${SCREEN_SIZE.LG} {
    font-size: ${STYLES.FONT_SIZE_LG};
  }
`;

export { BannerDescription };