import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { STYLES } from '../../../style';

const StyledSubSectionDescription = styled.div`
  color: ${STYLES.COLOR_DOVE_GRAY};
  line-height: 1.5rem;
  margin-bottom: 0.75rem;
`;

const SubSectionDescription = ({
  children,
}) => (
  <StyledSubSectionDescription>
    { children }
  </StyledSubSectionDescription>
);

SubSectionDescription.propTypes = {
  children: PropTypes.node.isRequired,
};

export { SubSectionDescription };