import React from 'react';
import styled from 'styled-components';
import {
  Banner, BannerContent, BannerDescription, BannerContentItem, BannerTitle,
} from '../../shared/Banner';
import { PageImage } from '../../shared/PageImage';
import LegalBannerIconSource from './images/legalBannerIcon.png';
import { SCREEN_SIZE, STYLES } from '../../../style';

const StyledLegalBanner = styled(Banner)``;

const BannerImage = styled(PageImage)`
  ${SCREEN_SIZE.LG} {
    padding-top: 3rem;
  }
`;

const LegalBanner = () => (
  <StyledLegalBanner color={STYLES.PAGE_LEGAL_BANNER_COLOR}>
    <BannerContent>
      <BannerContentItem>
        <BannerImage src={LegalBannerIconSource} />
      </BannerContentItem>
      <BannerContentItem>
        <BannerTitle>THE FINE PRINT</BannerTitle>
        <BannerDescription>
          Rules govern society to keep us safe and secure.
          Here are some of the rules that Hand Carry
          operates by to ensure that everyone
          involved is equally protected.
        </BannerDescription>
      </BannerContentItem>
    </BannerContent>
  </StyledLegalBanner>
);

export { LegalBanner };