import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import TermsOfUse from 'raw-loader!./md/TermsOfService.md';
import PaymentTerms from 'raw-loader!./md/PaymentTerms.md';
import PrivacyPolicy from 'raw-loader!./md/PrivacyPolicy.md';
import Insurance from 'raw-loader!./md/Insurance.md';
import Guarantee from 'raw-loader!./md/Guarantee.md';
import { Centralized } from '../../../shared/layout';
import { Section } from '../../../shared/Section';
import { LEGAL_TYPES } from './LegalTypes';
import { SCREEN_SIZE } from '../../../../style';
import { SideNavigation } from '../../../shared/SideNavigation';

const StyledLegalContent = styled(Section)`
  ${SCREEN_SIZE.LG} {
    display: flex;
    align-items: flex-start;
  }
`;

const LegalContentDetails = styled(ReactMarkdown)`
 h1 {
  margin-top: 0;
  line-height: 2rem;
 }
`;

const links = [
  {
    type: LEGAL_TYPES.TERMS_OF_SERVICE, text: 'Terms of service', url: '/legal/terms-of-service', markdown: TermsOfUse,
  },
  {
    type: LEGAL_TYPES.PAYMENT_TERMS, text: 'Payment terms', url: '/legal/payment-terms', markdown: PaymentTerms,
  },
  {
    type: LEGAL_TYPES.PRIVACY_POLICY, text: 'Privacy policy', url: '/legal/privacy-policy', markdown: PrivacyPolicy,
  },
  {
    type: LEGAL_TYPES.INSURANCE, text: 'Insurance', url: '/legal/insurance', markdown: Insurance,
  },
  {
    type: LEGAL_TYPES.GUARANTEE, text: 'Guarantee', url: '/legal/guarantee', markdown: Guarantee,
  },
];

const LegalContent = ({
  type,
}) => {
  const activeContent = links.find(link => link.type === type);
  if (!activeContent) { return null; }
  return (
    <Centralized>
      <StyledLegalContent>
        <SideNavigation activeLink={activeContent.url} links={links} />
        <LegalContentDetails
          source={activeContent.markdown}
        />
      </StyledLegalContent>
    </Centralized>
  );
};

LegalContent.propTypes = {
  type: PropTypes.string.isRequired,
};

export { LegalContent };