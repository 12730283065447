import styled from 'styled-components';
import { SCREEN_SIZE, STYLES } from '../../../style'

const BannerTitle = styled.div`
  margin-top: 5rem;
  text-transform: uppercase;
  color: ${STYLES.COLOR_WHITE};
  line-height: 2.25rem;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 1.6rem;
  
  ${SCREEN_SIZE.LG} {
    margin-bottom: .5rem;
    font-size: 1.75rem;
    margin-top: 0;
  }
`;

export { BannerTitle };