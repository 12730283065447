import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledSubSectionTitle = styled.div`
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
`;

const SubSectionTitle = ({ children, icon }) => {
  let Icon;
  if (icon) {
    Icon = styled(icon)`
    width: 2rem;
    height: 2rem;
    margin-right: .75rem;
  `;
  }
  return (
    <StyledSubSectionTitle>
      { Icon && <Icon /> }
      {children}
    </StyledSubSectionTitle>
  );
};

SubSectionTitle.defaultProps = {
  icon: null,
};

SubSectionTitle.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.func,
};

export { SubSectionTitle };