import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { BannerButton } from './BannerButton';
import { showModal } from '../../../states/modal/actions';
import { MODAL_TYPE } from '../ModalManager/constants';

const WatchVideoBannerButton = ({
  dispatch,
  videoId,
}) => (
  <BannerButton
    onClick={() => dispatch(showModal(MODAL_TYPE.YOUTUBE_MODAL, { videoId }))}
  >
    Watch Video
  </BannerButton>
);

WatchVideoBannerButton.defaultProps = {
  videoId: undefined,
};

WatchVideoBannerButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
  videoId: PropTypes.string,
};

const EnhancedWatchVideoBannerButton = compose(
  connect(),
)(WatchVideoBannerButton);

export { EnhancedWatchVideoBannerButton as WatchVideoBannerButton };
