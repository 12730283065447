import styled from 'styled-components';
import { Centralized } from '../layout';
import { SCREEN_SIZE } from '../../../style';

const BannerContent = styled(Centralized)`
  padding-top: 7rem;
  height: 100%;
  ${SCREEN_SIZE.LG} {
    padding-top: 5rem;
    display: flex;
    flex: 1;
    align-items: center;
  }
`;

export { BannerContent };