import styled from 'styled-components';
import { SCREEN_SIZE, STYLES } from '../../../style';

const PageTitle = styled.h1`
  margin: 0 0 1.5rem;
  font-size: ${STYLES.FONT_SIZE_XXLG};
  ${SCREEN_SIZE.LG} {
    font-size: ${STYLES.FONT_SIZE_XXXLG};
    padding-bottom: 3rem;
  }
`;

export { PageTitle };