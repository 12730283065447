import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledPage = styled.div`
  padding: 0;
`;

const Page = ({ children }) => (
  <StyledPage>
    { children }
  </StyledPage>
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
};

export { Page };