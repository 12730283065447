import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SCREEN_SIZE } from '../../../style';

const StyledBanner = styled.div`
  ${({ color }) => color && `background-color: ${color}`}
  width: 100%;
  min-height: 25rem;
  padding-bottom: 5rem;
  ${SCREEN_SIZE.LG} {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
  }
`;

const Banner = ({
  color,
  children,
}) => (
  <StyledBanner color={color}>
    {children}
  </StyledBanner>
);

Banner.propTypes = {
  color: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export { Banner };