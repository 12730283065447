import styled from 'styled-components';
import { SCREEN_SIZE } from '../../../style';

const PageImage = styled.img`
  margin: 1rem auto 2rem;
  display: block;
  max-width: 100%;
  max-height: 20rem;
  ${SCREEN_SIZE.LG} {
    margin: 0 auto;
  }
`;

export { PageImage };