import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from '../Link';
import { STYLES } from '../../../style';

const buttonStyle = `
  border: 6px solid ${STYLES.COLOR_WHITE};
  padding: 1rem 2rem;
  font-size: ${STYLES.FONT_SIZE_XXLG};
  color: ${STYLES.COLOR_WHITE};
  display: inline-block;
  font-weight: 600;
  margin-top: 2rem;
  text-transform: uppercase;
  cursor: pointer;
  .no-touch &:hover {
    color: ${STYLES.COLOR_WHITE};
  }
`;

const StyledBannerButtonLink = styled(Link)`
  ${buttonStyle}
`;

const StyledBannerButton = styled.div`
  ${buttonStyle}
`;

const BannerButton = ({
  to,
  children,
  onClick,
}) => {
  if (to) {
    return (
      <StyledBannerButtonLink to={to}>
        {children}
      </StyledBannerButtonLink>
    );
  }
  return (
    <StyledBannerButton onClick={onClick}>
      { children }
    </StyledBannerButton>
  )
};

BannerButton.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

export { BannerButton };