import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from '../Link';
import { SCREEN_SIZE, STYLES } from '../../../style';

const StyledSideNavigationItem = styled(Link)`
  display: block;
  margin-bottom: 1rem;
  color: ${STYLES.COLOR_DOVE_GRAY};
  margin-right: 2rem;
  padding: 0.5rem 1rem;
  background-color: ${STYLES.COLOR_SEASHELL_GRAY};
  border-radius: 1rem;
  ${({ active }) => active && `
    color: ${STYLES.COLOR_WHITE};
    background-color: ${STYLES.COLOR_BITTER_SWEET_RED};
    ${SCREEN_SIZE.LG} {
      cursor: default;
      && {
        text-decoration: none;
        color: ${STYLES.TEXT_COLOR};
      }
    }
  `}
  ${SCREEN_SIZE.LG} {
    padding: 0 1rem 1rem;
    margin-bottom: 0rem;
    margin-right: 0;
    white-space: nowrap;
    background-color: transparent;
    ${({ active }) => active && `
      color: ${STYLES.TEXT_COLOR};
      font-weight: 600;
    `}
  }
`;

const SideNavigationItem = ({
  children,
  url,
  active,
}) => (
  <StyledSideNavigationItem active={active} to={url}>
    {children}
  </StyledSideNavigationItem>
);

SideNavigationItem.propTypes = {
  children: PropTypes.node.isRequired,
  url: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
};

export { SideNavigationItem };