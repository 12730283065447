import styled from 'styled-components';

const SubSection = styled.div`
  ${({ noPadding }) => !noPadding && `
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  `}
  flex: 1;
`;

export { SubSection };