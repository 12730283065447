import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';


const HeaderText = ({ priority, children, className }) => {
  const HeaderElement = styled[`h${priority}`]`
    font-family
  `;

  return (
    <HeaderElement className={className}>
      {children}
    </HeaderElement>
  );
};

HeaderText.defaultProps = {
  className: null,
  priority: 1,
};

HeaderText.propTypes = {
  className: PropTypes.string,
  priority: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export { HeaderText };